<template>
  <v-row no-gutters>
    <v-col md="4" cols="12" class="pa-2">
      <v-card>
        <v-card-title>
          Sector List
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="sectorDialog" width="500">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary" small depressed fab>
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline primary white--text" primary-title>Sector</v-card-title>
              <v-card-text class="py-1">
                <v-form ref="sectorForm" v-model="validSector" v-on:submit.prevent lazy-validation>
                  <v-text-field
                    v-model="sector.sectorName"
                    placeholder="Sector Name"
                    :rules="sectorRule"
                    label="Sector Name"
                    name="sector"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="sector.shortName"
                    placeholder="Short Name"
                    :rules="shortNameRule"
                    label="Short Name"
                    name="sector"
                    counter="3"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="pt-1">
                <v-spacer></v-spacer>
                <v-btn
                  v-if="sector.status=='ADD'"
                  :disabled="!validSector"
                  @click="addSector"
                  color="success"
                  class="ma-1"
                  depressed
                >
                  <v-icon>add</v-icon>
                  <span class="ml-2">Add</span>
                </v-btn>
                <v-btn v-if="sector.status=='UPDATE'" :disabled="!validSector" color="primary" class="ma-1" @click="updateSector" depressed>
                  <v-icon>update</v-icon>
                  <span class="ml-2">Update</span>
                </v-btn>
                <v-btn color="error" class="ma-1" depressed outlined @click="cancelSector">
                  <v-icon>close</v-icon>
                  <span class="ml-2">Cancel</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="sectorHeader"
            :items="sectorList"
            :items-per-page="10"
            class="elevation-0"
            dense
          >
            <template v-slot:item.action="{ item }">
              <v-btn v-if="uiAccept.sedit" icon depressed color="primary" @click="editSector(item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn v-if="uiAccept.sdelete" icon depressed color="error" @click="deleteSector(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>  
    <v-col md="4" cols="12" class="pa-2">
      <v-card>
        <v-card-title>
          Passenger Type List
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="paxersDialog" width="500">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary" small depressed fab>
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline primary white--text" primary-title>Passenger Type</v-card-title>
              <v-card-text class="py-1">
                <v-form ref="paxerForm" v-model="validPaxers" v-on:submit.prevent lazy-validation>
                  <v-text-field
                    v-model="paxers.passTypeName"
                    placeholder="Passenger Name"
                    :rules="paxersRule"
                    label="Passenger Name"
                    name="paxers"
                    required
                  ></v-text-field>
                  <v-select
                    v-model="paxers.passType"
                    :items="passType"
                    label="Passenger Type"
                  ></v-select>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="pt-1">
                <v-spacer></v-spacer>
                <v-btn
                  v-if="paxers.status=='ADD'"
                  :disabled="!validPaxers"
                  @click="addPaxers"
                  color="success"
                  class="ma-1"
                  depressed
                >
                  <v-icon>add</v-icon>
                  <span class="ml-2">Add</span>
                </v-btn>
                <v-btn v-if="paxers.status=='UPDATE'" :disabled="!validPaxers" color="primary" class="ma-1" @click="updatePaxers" depressed>
                  <v-icon>update</v-icon>
                  <span class="ml-2">Update</span>
                </v-btn>
                <v-btn color="error" class="ma-1" depressed outlined @click="cancelPaxers">
                  <v-icon>close</v-icon>
                  <span class="ml-2">Cancel</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="paxersHeader"
            :items="paxersList"
            :items-per-page="10"
            class="elevation-0"
            dense
          >
            <template v-slot:item.action="{ item }">
              <v-btn v-if="uiAccept.sedit" icon depressed color="primary" @click="editPaxers(item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn v-if="uiAccept.sdelete" icon depressed color="error" @click="deletePaxers(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col md="4" cols="12" class="pa-2">
      <v-card>
        <v-card-title>
          Class List
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="classesDialog" width="500">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="primary" small depressed fab>
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline primary white--text" primary-title>Classes</v-card-title>
              <v-card-text class="py-1">
                <v-form ref="form" v-model="validClasses" v-on:submit.prevent lazy-validation>
                  <v-text-field
                    required
                    v-model="classes.className"
                    :rules="classesRule"
                    name="classes"
                    placeholder="Class Name"
                    label="Class Name"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="pt-1">
                <v-spacer></v-spacer>
                <v-btn
                  v-if="classes.status=='ADD'"
                  :disabled="!validClasses"
                  @click="addClasses"
                  color="success"
                  class="ma-1"
                  depressed
                >
                  <v-icon>add</v-icon>
                  <span class="ml-2">Add</span>
                </v-btn>
                <v-btn v-if="classes.status=='UPDATE'" :disabled="!validClasses" color="primary" class="ma-1" @click="updateClasses" depressed>
                  <v-icon>update</v-icon>
                  <span class="ml-2">Update</span>
                </v-btn>
                <v-btn color="error" class="ma-1" depressed outlined @click="cancelClasses">
                  <v-icon>close</v-icon>
                  <span class="ml-2">Cancel</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="classesHeader"
            :items="classesList"
            :items-per-page="10"
            class="elevation-0"
            dense
          >
            <template v-slot:item.action="{ item }">
              <v-btn v-if="uiAccept.sedit" icon depressed color="primary" @click="editClasses(item)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn v-if="uiAccept.sdelete" icon depressed color="error" @click="deleteClasses(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import settingService from "../service/SettingService";

export default {
  data() {
    return {
      validSector: false,
      sectorDialog: false,
      sector: {
        sectorName: null,
        shortName: null,
        status: "ADD"
      },
      sectorRule: [v => !!v || "Sector Name is required"],
      shortNameRule: [v => !!v && v.length == 3 || 'Sector Name must be 3 characters'],
      sectorHeader: [
        { text: "Sector Name", value: "sectorName" },
        { text: "Short Name", value: "shortName" },
        { text: "Action", value: "action" }
      ],
      sectorList: [],

      validPaxers: false,
      paxersDialog: false,
      paxers: {
        passTypeName: null,
        passType: 'ADULT',
        status: "ADD"
      },
      paxersRule: [v => !!v || "Passenger Name is required"],
      passType: ['ADULT', 'CHILD', 'INFANT'],
      paxersHeader: [
        { text: "Passenger Name", value: "passTypeName" },
        { text: "Passenger Type", value: "passType" },
        { text: "Action", value: "action" }
      ],
      paxersList: [],

      validClasses: false,
      classesDialog: false,
      classes: {
        name: null,
        status: "ADD"
      },
      classesRule: [v => !!v || "Classes Name is required"],
      classesHeader: [
        { text: "Classes Name", value: "className" },
        { text: "Action", value: "action" }
      ],
      classesList: [],
      uiAccept:{},
    };
  },
  mounted: function() {
    this.uiAccept = this.$store.state.uiAccept;
    this.getClasses();
    this.getSector();
    this.getPaxers();
  },
  methods: {
    // sector
    
    getSector: function() {
      settingService.getSector().then(response => {
        this.sectorList.splice(0, this.sectorList.length);
        this.sectorList.push(...response);
      });
    },
    addSector: function() {
      if (this.$refs.sectorForm.validate()) {
        settingService
          .addSector(this.sector)
          .then(() => {
            this.$swal("Successful", "Added New Sector Successful!", "success");
            this.getSector();
            this.$refs.sectorForm.reset();
            this.$refs.sectorForm.resetValidation();
            this.sectorDialog = false;
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    editSector: function(sector) {
      sector.status = "UPDATE";
      this.sector = Object.assign({}, sector);
      this.sectorDialog = true;
    },
    updateSector: function() {
      if (this.$refs.sectorForm.validate()) {
        settingService
          .updateSector(this.sector)
          .then(() => {
            this.$swal("Updated Successful", "Sector is Updated!", "success");
            this.getSector();
            this.$refs.sectorForm.reset();
            this.$refs.sectorForm.resetValidation();
            this.sector.status = "ADD";
            this.sectorDialog = false;
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    deleteSector: function(sector) {
      settingService
        .deleteSector(sector)
        .then(() => {
          this.$swal("Delete Successful", "Sector is Deleted!", "success");
          this.getSector();
          this.$refs.sectorForm.reset();
          this.$refs.sectorForm.resetValidation();
          this.sector.status = "ADD";
          this.sectorDialog = false;
        })
        .catch(err => {
          this.$swal(
            "Failed",
            "This data is used in somewhere Or " + err.response.data.message,
            "error"
          );
        });
    },
    cancelSector: function(){
      this.sector={
        sectorName:null,
        shortName:null,
        status:'ADD'
      }
      this.sectorDialog = false;
    },

    // paxers
    getPaxers: function() {
      settingService.getPaxers().then(response => {
        this.paxersList.splice(0, this.paxersList.length);
        this.paxersList.push(...response);
      });
    },
    addPaxers: function() {
      if (this.$refs.paxerForm.validate()) {
        settingService
          .addPaxers(this.paxers)
          .then(() => {
            this.$swal("Successful", "Added New Paxers Successful!", "success");
            this.getPaxers();
            this.$refs.paxerForm.reset();
            this.$refs.paxerForm.resetValidation();
            this.paxers={
              passTypeName: null,
              passType: 'ADULT',
              status: "ADD"
            }
            console.log("this.paxers");
            console.log(this.paxers);
            this.paxersDialog = false;
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    editPaxers: function(paxers) {
      paxers.status = "UPDATE";
      this.paxers = Object.assign({}, paxers);
      this.paxersDialog = true;
    },
    updatePaxers: function() {
      if (this.$refs.paxerForm.validate()) {
        settingService
          .updatePaxers(this.paxers)
          .then(() => {
            this.$swal("Updated Successful", "Paxers is Updated!", "success");
            this.getPaxers();
            this.$refs.paxerForm.reset();
            this.$refs.paxerForm.resetValidation();
            this.paxers.status = "ADD";
            this.paxersDialog = false;
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    deletePaxers: function(paxers) {
      settingService
        .deletePaxers(paxers)
        .then(() => {
          this.$swal("Delete Successful", "Paxers is Deleted!", "success");
          this.getPaxers();
          this.$refs.paxerForm.reset();
          this.$refs.paxerForm.resetValidation();
          this.paxers.status = "ADD";
          this.paxersDialog = false;
        })
        .catch(err => {
          this.$swal(
            "Failed",
            "This data is used in somewhere Or " + err.response.data.message,
            "error"
          );
        });
    },
    cancelPaxers: function(){
      this.paxers={
        passTypeName: null,
        passType: 'ADULT',
        status: "ADD"
      }
      this.paxersDialog = false;
    },

    // classes
    getClasses: function() {
      settingService.getClasses().then(response => {
        this.classesList.splice(0, this.classesList.length);
        this.classesList.push(...response);
      });
    },
    addClasses: function() {
      if (this.$refs.form.validate()) {
        settingService
          .addClasses(this.classes)
          .then(() => {
            this.$swal("Successful", "Added New Classes Successful!", "success");
            this.getClasses();
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.classesDialog = false;
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    editClasses: function(classes) {
      classes.status = "UPDATE";
      this.classes = Object.assign({}, classes);
      this.classesDialog = true;
    },
    updateClasses: function() {
      if (this.$refs.form.validate()) {
        settingService
          .updateClasses(this.classes)
          .then(() => {
            this.$swal("Updated Successful", "Classes is Updated!", "success");
            this.getClasses();
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.classes.status = "ADD";
            this.classesDialog = false;
          })
          .catch(err => {
            this.$swal("Failed", err.response.data.message, "error");
          });
      }
    },
    deleteClasses: function(classes) {
      settingService
        .deleteClasses(classes)
        .then(() => {
          this.$swal("Delete Successful", "Classes is Deleted!", "success");
          this.getClasses();
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.classes.status = "ADD";
          this.classesDialog = false;
        })
        .catch(err => {
          this.$swal(
            "Failed",
            "This data is used in somewhere Or " + err.response.data.message,
            "error"
          );
        });
    },
    cancelClasses: function(){
      this.classes={
        className:"",
        status:'ADD'
      }
      this.classesDialog = false;
    }
  }
};
</script>